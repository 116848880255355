import "../auxillary.scss"
import React from "react"
import { FooterContent } from "../../Footer/Footer"

import clientImageOne from "../../../images/terms/client_1.png"
import clientImageTwo from "../../../images/terms/client_2.png"
import clientImageThree from "../../../images/terms/client_3.png"

function ClientPrivacyPolicy() {
  return (
    <>
      <section className="auxillary-wrapper">
        <div className="auxillary cookies-policy">
          <div className="auxillary__inner">
            <h1>Client Privacy Policy</h1>
            <div className="auxillary__content-column">
              <p>Effective: January 1, 2023.</p>

              <h2>United Talent Agency, LLC</h2>

              <p>
                This privacy notice (“Notice”) describes to our clients the U.S.
                privacy policies (“Policy”) of United Talent Agency, LLC. (“us”
                or “we”).
              </p>

              <h2>Who is Covered by this Privacy Notice?</h2>

              <p>
                This Notice is intended to summarize our data handling practices
                and applies to both current and former clients who are
                individuals residing in the United States. The Policy does not
                apply to clients who are non-U.S residents, or to corporations,
                partnerships, or other entities that are not individuals.
              </p>

              <p>
                This Notice summarizes how we collect and share personal
                information about you; and how you can limit our sharing or
                certain uses of your information. This policy may change from
                time to time so please check this webpage occasionally to ensure
                you are up to date on any changes we may have made.
              </p>

              <h2>We Respect Your Privacy</h2>

              <p>
                United Talent Agency, LLC respects individuals&apos; privacy and
                is committed to protecting it.
              </p>

              <h2>What Personal Information Do We Have and Share About You?</h2>

              <p>
                In representing you, we regularly request, receive, process and
                maintain personal information about you. All such information is
                referred to as “your information” in this notice. This
                information may include:
              </p>

              <p>
                Information provided by you, including information from
                documents and other forms we receive from you or while
                representing you;
              </p>

              <p>
                Information about your professional activities related to
                transactions with nonaffiliated third parties.
              </p>

              <p>
                Information about your professional activities related to
                transactions with us or our affiliates.
              </p>

              <h2>How do We Protect Your Information?</h2>

              <p>
                We maintain electronic, procedural, and physical safeguards
                intended to protect your information. While we employ these
                safeguards, we also rely on you to help us identify threats to
                your information. So, if you become a victim of identity theft
                or fraud, or notice any suspicious activity relating to your
                personal information, please alert us as soon as possible.
              </p>

              <h2>With Whom Do We Share Your Personal Information?</h2>

              <p>
                In order to provide services to you, we may need to share your
                personal information. The ways we may share your information
                include:
              </p>

              <p>
                Disclosing your information in connection with our efforts to
                procure engagements or employment for you, to process payments
                in connection with your professional activities, to respond to
                legal process, or to perform other services on your behalf that
                you have asked us to perform.
              </p>

              <p>
                Sharing your information with our affiliates (entities related
                to us through common ownership), so they may offer services to
                you and help us serve you better.
              </p>

              <p>
                Sharing your information with non-affiliated service providers
                such as banks, custodians, attorneys, and administrators, to
                enable them to perform services on our behalf, facilitate your
                professional activities, or as otherwise permitted or required
                by law.
              </p>

              <p>
                You may request that we limit our sharing of your information to
                our affiliates or others for the purpose of offering you
                services, by emailing us at{" "}
                <a href="mailto:privacy@unitedtalent.com">
                  privacy@unitedtalent.com
                </a>
                .
              </p>

              <h2>
                How this Privacy Policy Relates to Your Agency or Representation
                Agreement
              </h2>

              <p>
                The purpose of this notice is designed to help you better
                understand how we handle your personal information and is
                intended to incorporate the terms of the representation
                agreement we may have with you. Should any of the terms
                contained in this notice differ from those contained in your
                representation agreement with us, the terms of your
                representation agreement will control the use of any information
                you choose to provide or give us access to.
              </p>

              <h2>How to Contact Us About Privacy Matters </h2>

              <p>
                Should you have any questions regarding this privacy policy
                please email us at{" "}
                <a href="mailto:privacy@unitedtalent.com">
                  privacy@unitedtalent.com
                </a>
                .
              </p>

              <h2>CCPA Client Privacy Notice for California Residents</h2>

              <p>
                We collect your personal information, as defined under
                California Consumer Privacy Act (Cal. Civ. Code § 1798.100, et.
                seq.) to support its business operations, including for the
                business purposes listed below.
              </p>

              <p>
                We do not sell the personal information we collect from our
                clients. We also do not share client personal information with
                third parties for cross-context behavioral advertising.
              </p>

              <p>
                We may collect the personal information categories listed below.
                The information lists, for each category, our business purpose
                for collecting the information and our expected retention
                period. Please note that in some situations, we may need to
                retain certain categories of personal information for longer
                than the disclosed period in order to comply with our legal or
                regulatory obligations, resolve disputes, defend against legal
                claims, or in other exigent circumstances.
              </p>

              <img src={clientImageOne} alt="client One" />
              <img src={clientImageTwo} alt="client Two" />
              <img src={clientImageThree} alt="client Three" />

              <p>
                If you have any questions about this Notice, please contact{" "}
                <a href="mailto:privacy@unitedtalent.com">
                  privacy@unitedtalent.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="auxillary-footer">
        <FooterContent selected />
      </section>
    </>
  )
}

export default ClientPrivacyPolicy
